import React, { useEffect, useState } from 'react';
import Header from '../components/common/Header';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import AddOrganizationModal from '../components/modals/AddOrganizationModal';
import OrganizationTable from '../components/tables/OrganizationTable';
import { useOrganizations } from '../context/OrganizationContext';

const Organization = () => {
  const { t } = useTranslation();
  const { organizations, fetchOrganizations, isLoading } = useOrganizations();
  const [filtering, setFiltering] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

//   const onPageChange = (page) => {
//     setFiltering((prev) => ({
//       ...prev,
//       currentPage: page,
//     }));
//   };

  const onSortBy = (sortKey) => {
    let checkSortKey = false;
    if (filtering?.sortBy === sortKey) {
      checkSortKey = true;
    }
    setFiltering((prev) => ({
      ...prev,
      sortBy: `${sortKey}`,
      sortDirection: `${checkSortKey ? (prev.sortDirection === 'DESC' ? 'ASC' : 'DESC') : 'DESC'}`,
    }));
  };

  const onOrganizationChange = () => {
    setFiltering((prev) => ({
      ...prev,
    }));
  };

  const getOrganizations = async (searchData) => {
    try {
      await fetchOrganizations(searchData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getOrganizations(filtering);
  }, [filtering]);

  const columns = [
    {
      key: 'schoolName',
      value: t('school_name'),
      sortable: false,
    },
    {
      key: 'lat',
      value: t('latitude'),
      sortable: false,
    },
    {
      key: 'lon',
      value: t('longitude'),
      sortable: false,
    },
    {
      key: 'radius',
      value: t('radius'),
      sortable: false,
    },
    {
        key: 'shouldCheckLocation',
        value: t('check_location'),
        sortable: false,
    },
    {
      key: 'action',
      value: t('action'),
      sortable: false,
    },
    {
      key: 'delete',
      value: '',
      sortable: false,
    },
  ];

  return (
    <>
      <Header title={t('organization')}></Header>
      {isLoading ? (
        <div className='mt-5'>
          <LoadingSpinner isLoading={isLoading} />
        </div>
      ) : (
        <div className='my-5'>
          <Button className='bg-tr-blue flex mb-5' onClick={handleModalToggle}>
            {t('add_organization')}
          </Button>
          <OrganizationTable
            columns={columns}
            entries={organizations}
            // pagination={testData.pagination}
            // onPageChange={onPageChange}
            onSortBy={onSortBy}
            onOrganizationChange={onOrganizationChange}
          />
        </div>
      )}
      {isModalOpen && <AddOrganizationModal onClose={handleModalToggle} isOpen={isModalOpen} onOrganizationChange={onOrganizationChange}/>}
    </>
  );
};

export default Organization;
