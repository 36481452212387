import React from "react";

const Button = ({ children, className, onClick, ...rest }) => {
  return (
    <button
      className={`rounded-xl py-2 px-4 text-white font-semibold hover:opacity-90 ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
