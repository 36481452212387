import React, { useState, useCallback } from "react";
import Button from "./Button";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";

const SearchAndFilter = ({ onFilterChange, onSearchTermChange, pageType }) => {
  const { t } = useTranslation();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    date: "",
    classGroups: "",
    hideByod: "",
  });

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      onSearchTermChange(query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const clearSearchTerm = () => {
    setSearchTerm("");
    debouncedSearch("");
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? (checked ? checked : "") : value,
    }));
  };

  const handleSearchClick = () => {
    const requestData = {
      searchTerm,
      ...filters,
    };
    onFilterChange(requestData);
  };

  return (
    <div className="items-center text-xs w-full">
      <div className="relative">
        <input
          type="text"
          placeholder={t("search")}
          value={searchTerm}
          onChange={handleSearchChange}
          className="pl-10 pr-3 py-5 border border-tr-medium-gray outline-none rounded-full text-sm w-full sm:w-[450px] xl:w-[550px]"
        />
        <Icon
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-tr-gray"
          name="magnifyingGlassCircle"
        />
        <div className="absolute flex items-center space-x-3 right-4 top-1/2 transform -translate-y-1/2 text-tr-blue cursor-pointer">
          <Icon name="funnel" onClick={toggleFilterDropdown} />
          <span className="border rounded-full border-tr-medium-gray p-2 text-tr-black">
            <Icon name="xMark" onClick={clearSearchTerm} />
          </span>
        </div>
      </div>
      {showFilterDropdown && (
        <div className="absolute space-y-4 border border-tr-medium-gray rounded-2xl p-4 my-4 bg-white w-[300px] sm:w-[450px] xl:w-[550px]">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex border border-tr-medium-gray items-center rounded-full sm:w-[210px] xl:w-full">
              <div className="border-r border-tr-medium-gray rounded-l-2xl flex p-2 bg-tr-light-gray min-w-[79px]">
                <span className="">{t("first_name")}</span>
              </div>
              <div>
                <input
                  type="text"
                  name="firstName"
                  value={filters.firstName}
                  onChange={handleFilterChange}
                  className="outline-none m-1 w-full"
                />
              </div>
            </div>
            <div className="flex border border-tr-medium-gray items-center rounded-full sm:w-[210px] xl:w-full">
              <div className="border-r border-tr-medium-gray rounded-l-2xl flex p-2 bg-tr-light-gray min-w-[79px]">
                <span className="">{t("last_name")}</span>
              </div>
              <div>
                <input
                  type="text"
                  name="lastName"
                  value={filters.lastName}
                  onChange={handleFilterChange}
                  className="outline-none m-1 w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 flex-col sm:flex-row">
            <div className="flex border border-tr-medium-gray items-center rounded-full sm:w-[200px] xl:w-full">
              <div className="border-r border-tr-medium-gray rounded-l-2xl flex p-2 bg-tr-light-gray min-w-[79px]">
                <span className="">{t("class")}</span>
              </div>
              <div>
                <input
                  type="text"
                  name="classGroups"
                  value={filters.classGroups}
                  onChange={handleFilterChange}
                  className="outline-none m-1"
                />
              </div>
            </div>
            {pageType === "attendance" && (
              <div className="flex border border-tr-medium-gray items-center rounded-full sm:w-[200px] xl:w-full">
                <div className="border-r border-tr-medium-gray rounded-l-2xl flex p-2 bg-tr-light-gray min-w-[79px]">
                  <span className="">{t("date")}</span>
                </div>
                <div>
                  <input
                    type="date"
                    name="date"
                    value={filters.date}
                    onChange={handleFilterChange}
                    className="outline-none m-1 sm:min-w-[110px] xl:min-w-[160px]"
                  />
                </div>
              </div>
            )}
          </div>
          {pageType === "attendance" && (
            <div className="flex ml-2">
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="hideByod"
                  checked={filters.hideByod}
                  onChange={handleFilterChange}
                />
                <span>{t("hide_byod")}</span>
              </div>
            </div>
          )}
          <Button
            className="bg-tr-blue flex w-full justify-center"
            onClick={handleSearchClick}
          >
            <Icon name="magnifyingGlassCircle" className="mr-1" />
            {t("search_button")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchAndFilter;
