import Button from "../common/Button";
import Table from "../common/Table";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AttendanceTable = ({
  entries,
  columns,
  pagination,
  onPageChange,
  onSortBy,
  changeStatus,
}) => {
  const { t } = useTranslation();
  return (
    <Table
      columns={columns}
      pagination={pagination}
      onPageChange={onPageChange}
      onSortBy={onSortBy}
    >
      <tbody>
        {entries &&
          entries.map((entry) => (
            <tr
              key={entry.id}
              className="border-b border-tr-medium-gray text-sm"
            >
              {columns.map((column) => {
                if (column.key === "isConfirmed") {
                  return (
                    <td key={column.key} className="flex p-4">
                      <Button
                        className={`text-white font-normal ${
                          entry[column.key] ? "bg-tr-green" : "bg-tr-red"
                        }`}
                        onClick={() => changeStatus(entry.id)}
                      >
                        {entry[column.key] === true
                          ? t("confirmed")
                          : t("unconfirmed")}
                      </Button>
                    </td>
                  );
                } else if (
                  column.key === "checkIn" ||
                  column.key === "checkOut"
                ) {
                  return (
                    <td key={column.key} className="h-12 px-4 text-left">
                      <div className="flex gap-2 items-center text-tr-gray-600">
                        {entry[column.key]
                          ? moment(entry[column.key]).format("HH:mm")
                          : "/"}
                      </div>
                    </td>
                  );
                } else if (
                  column.key === "firstName" ||
                  column.key === "lastName"
                ) {
                  return (
                    <td key={column.key} className="h-12 px-4 text-left">
                      <div className="flex gap-2 items-center text-tr-gray-600">
                        {entry.user[column.key]}
                      </div>
                    </td>
                  );
                } else if (column.key === "classGroups") {
                  return (
                    <td key={column.key} className="h-12 px-4 text-left">
                      <div className="flex gap-2 items-center text-tr-gray-600">
                        {entry.user[column.key][0].name}
                      </div>
                    </td>
                  );
                } else if (column.key === "date") {
                  return (
                    <td key={column.key} className="h-12 px-4 text-left">
                      <div className="flex gap-2 items-center text-tr-gray-600">
                        {moment(entry["checkIn"]).format("DD.MM.YYYY.")}
                      </div>
                    </td>
                  );
                } else {
                  return (
                    <td key={column.key} className="h-12 px-4 text-left">
                      <div className="flex gap-2 items-center text-tr-gray-600">
                        {entry[column.key]}
                      </div>
                    </td>
                  );
                }
              })}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default AttendanceTable;
