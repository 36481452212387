import { useState } from "react";
import Icon from "../common/Icon";
import Table from "../common/Table";
import { useTranslation } from "react-i18next";
import EditOrganizationModal from "../modals/EditOrganizationModal";
import DeleteOrganizationModal from "../modals/DeleteOrganizationModal";

const OrganizationTable = ({
  entries,
  columns,
  onPageChange,
  onSortBy,
  onOrganizationChange,
}) => {
  const { t } = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [school, setSchool] = useState({
    schoolName: "",
    lat: "",
    lon: "",
    radius: "",
  });
  const toggleEditModal = (isModalOpen, entry) => {
    if (!isModalOpen) {
      setSchoolId(entry.id);
      setSchool({
        schoolName: entry.schoolName,
        lat: entry.config?.coordinates.lat ?? "",
        lon: entry.config?.coordinates.lon ?? "",
        radius: entry.config?.coordinates.radius ?? "",
        shouldCheckLocation: entry.config?.shouldCheckLocation ?? false
      });
    }
    setIsEditModalOpen(!isModalOpen);
  };
  const toggleDeleteModal = (isModalOpen, entry) => {
    if (!isModalOpen) {
      setSchoolId(entry.id);
    }
    setIsDeleteModalOpen(!isModalOpen);
  };
  return (
    <>
      <Table
        columns={columns}
        onPageChange={onPageChange}
        onSortBy={onSortBy}
      >
        <tbody>
          {entries &&
            entries.map((entry) => (
              <tr
                key={entry.id}
                className="border-b border-tr-medium-gray text-sm"
              >
                {columns.map((column) => {
                  if (column.key === "action") {
                    return (
                      <td key={column.key} className="h-12 px-4 text-left">
                        <div className="flex items-center gap-1">
                          <button
                            onClick={(prev) => toggleEditModal(!prev, entry)}
                          >
                            <Icon
                              name="settings"
                              className="text-tr-gray-600"
                            />
                          </button>
                          <p className="text-tr-gray-600">{t("edit")}</p>
                        </div>
                      </td>
                    );
                  } else if (column.key === "delete") {
                    return (
                      <td key={column.key} className="px-2">
                        <button
                          className="flex items-center justify-center"
                          onClick={(prev) => toggleDeleteModal(!prev, entry)}
                        >
                          <Icon name="delete" className="text-tr-gray-600" />
                        </button>
                      </td>
                    );
                  } else if (column.key === "lat" || column.key === "lon" || column.key === "radius") {
                    return (
                        <td key={column.key} className="h-12 px-4 text-left">
                          <div className="flex gap-2 items-center text-tr-gray-600">
                            {entry.config?.coordinates[column.key]}
                          </div>
                        </td>
                      );
                  } else if (column.key === "shouldCheckLocation") {
                    return (
                        <td key={column.key} className="h-12 px-4 text-left">
                          <div className="flex gap-2 items-center text-tr-gray-600">
                            <input
                                type="checkbox"
                                checked={entry.config?.[column.key]}
                                disabled
                            />
                          </div>
                        </td>
                      );
                  } else {
                    return (
                      <td key={column.key} className="h-12 px-4 text-left">
                        <div className="flex gap-2 items-center text-tr-gray-600">
                          {entry[column.key]}
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </Table>
      {isEditModalOpen && <EditOrganizationModal
        isOpen={isEditModalOpen}
        onClose={toggleEditModal}
        onOrganizationChange={onOrganizationChange}
        passedSchool={school}
        selectedSchoolId={schoolId}
      />}
      <DeleteOrganizationModal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        onOrganizationChange={onOrganizationChange}
        schoolId={schoolId}
      />
    </>
  );
};

export default OrganizationTable;
