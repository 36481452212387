import { useTranslation } from "react-i18next";

const Switch = ({ isOn, isLoggedIn, onToggle }) => {
  const { t } = useTranslation();
  return (
    <label
      className={`relative inline-flex items-center ${
        isLoggedIn ? "cursor-pointer" : ""
      }`}
    >
      <input
        type="checkbox"
        checked={isOn}
        onChange={onToggle}
        className="sr-only peer"
        disabled={isLoggedIn ? false : true}
      />
      <div
        className={`w-20 h-6 flex justify-center items-center rounded-full peer transition ${
          !isLoggedIn ? "bg-tr-medium-gray" : isOn ? "bg-tr-green" : "bg-tr-red"
        }`}
      >
        <span
          className={`text-xs ${
            isLoggedIn ? "text-white" : "text-tr-gray-600"
          }`}
        >
          {isOn ? t("on") : t("off")}
        </span>
      </div>
      <div
        className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition transform ${
          isOn ? "translate-x-14" : ""
        }`}
      ></div>
    </label>
  );
};

export default Switch;
