import React from "react";
import { useTranslation } from "react-i18next";

const TimeFrame = ({ dayOfWeek, timeValue, handleFilterChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex items-center bg-white rounded-full border border-tr-medium-gray">
          <div className="flex w-full p-2 h-full bg-tr-medium-gray rounded-l-full min-w-[60px]">
            <span>{t("from")}</span>
          </div>
          <div>
            <input
              type="time"
              name={dayOfWeek}
              value={timeValue.from}
              onChange={(e) => handleFilterChange(e, "from")}
              className="mx-2 outline-none"
            />
          </div>
        </div>
        <div className="flex items-center bg-white rounded-full border border-tr-medium-gray">
          <div className="flex w-full p-2 h-full bg-tr-medium-gray rounded-l-full min-w-[60px]">
            <span>{t("to")}</span>
          </div>
          <div>
            <input
              type="time"
              name={dayOfWeek}
              value={timeValue.to}
              onChange={(e) => handleFilterChange(e, "to")}
              className="mx-2 outline-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeFrame;
