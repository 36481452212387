import React from "react";

const Modal = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="fixed inset-0 bg-tr-black bg-opacity-50 z-50"></div>
      <div
        onClick={onClose}
        className="fixed inset-0 z-[100] flex items-center justify-center"
      >
        <div
          onClick={handleModalContentClick}
          className="bg-white rounded-2xl w-screen sm:w-auto"
        >
          <div className="px-7 pt-6">
            <h2 className="text-lg text-tr-gray-600">{title}</h2>
          </div>
          <div className="p-6">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
