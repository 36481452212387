import React, { createContext, useState, useContext } from "react";
import orgService from "../services/organizations";
import ContextResponseModal from "../components/modals/ContextResponseModal";
import { useTranslation } from "react-i18next";

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({
    isOpen: false,
    error: false,
    message: ""
  });

  const toggleModal = (isModalOpen) => {
    setStatus({
      isOpen: !isModalOpen,
      error: false,
      message: ""
    })
  };

  const fetchOrganizations = async (data) => {
    setIsLoading(true);
    try {
      const response = await orgService.getAllOrganizations(data);
      setOrganizations(response.data);
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message
      })
      setIsLoading(false);
    }
  };

  const addOrganization = async (data) => {
    setIsLoading(true);
    try {
      await orgService.addOrganization(data);
      setStatus({
        isOpen: true,
        error: false,
        message: t("import_successful")
      })
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message
      })
      setIsLoading(false);
    }
  }

  const editOrganization = async (id, data) => {
    setIsLoading(true);
    try {
      await orgService.editOrganizationById(id, data);
      setStatus({
        isOpen: true,
        error: false,
        message: t("edit_successful")
      })
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message
      })
      setIsLoading(false);
    }
  }

  const deleteOrganization = async (id) => {
    setIsLoading(true);
    try {
      await orgService.deleteOrganizationById(id);
      setStatus({
        isOpen: true,
        error: false,
        message: t("deleted_successfully")
      })
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message
      })
      setIsLoading(false);
    }
  }

  return (
    <>
      <OrganizationContext.Provider value={{ organizations, fetchOrganizations, isLoading, addOrganization, editOrganization, deleteOrganization }}>{children}</OrganizationContext.Provider>
      <ContextResponseModal status={status} isOpen={status.isOpen} onClose={toggleModal} />
    </>
  );
};

export const useOrganizations = () => useContext(OrganizationContext);

export default OrganizationProvider;
