import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

const Navbar = ({ isLogoutOnly }) => {
  const { logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const LogoutBtn = ({ classNames }) => {
    return (
      <img
        onClick={handleLogout}
        className={`px-6 h-[22px] mt-[10px] cursor-pointer hover:opacity-70 ${classNames}`}
        src={process.env.PUBLIC_URL + "/logout.png"}
        tabIndex="0"
        alt="logo"
      />
    );
  };

  return (
    <>
      {!isLogoutOnly && (
        <nav className="flex justify-between pt-4">
          <img
            className="px-6 h-[39px]"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="logo"
          />
          <LogoutBtn />
        </nav>
      )}
      {isLogoutOnly && <LogoutBtn classNames={"absolute right-0"} />}
    </>
  );
};

export default Navbar;
