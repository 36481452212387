export const ROLE_NAMES = {
    STUDENT: 'STUDENT',
    TEACHER: 'TEACHER',
    ORG_ADMIN: 'ORG_ADMIN',
    SCHOOL_ADMIN: 'SCHOOL_ADMIN',
    TR_ADMIN: 'TR_ADMIN',
};

export const PERMS = {
    BACKEND_ACCESS: 'backend_access',
    ATTENDANCE_ALL: 'attendance_all',
    ATTENDANCE_OWN: 'attendance_own',
    RESET_PASSWORD: 'reset_password',
    MANAGE_BYOD: 'manage_byod',
    MANAGE_USERS: 'manage_users',
    MANAGE_ORGANIZATIONS: 'manage_organizations'
};

export const DEFAULT_SCHOOL_HOURS = {
    monday: {
        from: '08:00',
        to: '14:00',
    },
    tuesday: {
        from: '08:00',
        to: '14:00',
    },
    wednesday: {
        from: '08:00',
        to: '14:00',
    },
    thursday: {
        from: '08:00',
        to: '14:00',
    },
    friday: {
        from: '08:00',
        to: '14:00',
    },
};