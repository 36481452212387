import React from "react";

const Header = ({ children, title }) => {
  return (
    <div className="flex flex-col sm:flex-row p-0 justify-between items-center border-b border-tr-medium-gray">
      <span className="py-9 font-bold text-2xl font-medium leading-7">
        {title}
      </span>
      <span className="flex">{children}</span>
    </div>
  );
};

export default Header;
