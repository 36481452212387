import React, { useEffect, useState } from 'react';
import UserAdministrationTable from '../components/tables/UserAdministrationTable';
import Header from '../components/common/Header';
import { useUsers } from '../context/UsersContext';
import SearchAndFilter from '../components/common/SearchAndFilter';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import AddUserModal from '../components/modals/AddUserModal';

const UserAdministration = () => {
  const { t } = useTranslation();
  const { users, fetchUsers, exportUsers, isLoading } = useUsers();
  const [filtering, setFiltering] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onPageChange = (page) => {
    setFiltering((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };

  const onSortBy = (sortKey) => {
    let checkSortKey = false;
    if (filtering?.sortBy === sortKey) {
      checkSortKey = true;
    }
    setFiltering((prev) => ({
      ...prev,
      currentPage: 1,
      sortBy: `${sortKey}`,
      sortDirection: `${checkSortKey ? (prev.sortDirection === 'DESC' ? 'ASC' : 'DESC') : 'DESC'}`,
    }));
  };

  const onFilterChange = (filterValues) => {
    setFiltering((prev) => ({
      ...prev,
      firstName: filterValues.firstName,
      lastName: filterValues.lastName,
      classGroups: filterValues.classGroups,
      currentPage: 1,
    }));
  };

  const onSearchTermChange = (term) => {
    setFiltering((prev) => ({
      ...prev,
      searchTerm: term,
      currentPage: 1,
    }));
  };

  const onUserChange = () => {
    setFiltering((prev) => ({
      ...prev,
    }));
  };

  const getUsers = async (searchData) => {
    try {
      await fetchUsers(searchData);
    } catch (e) {
      console.log(e);
    }
  };

  const exportData = async () => {
    try {
      await exportUsers(filtering);
    } catch (e) {
      console.log(e);
    }
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getUsers(filtering);
  }, [filtering]);

  const columns = [
    {
      key: 'firstName',
      value: t('first_name'),
      sortable: true,
    },
    {
      key: 'lastName',
      value: t('last_name'),
      sortable: true,
    },
    {
      key: 'classGroups',
      value: t('class'),
      sortable: true,
    },
    {
      key: 'username',
      value: t('user_name'),
      sortable: false,
    },
    {
      key: 'action',
      value: t('action'),
      sortable: false,
    },
    {
      key: 'email',
      value: t('email'),
      sortable: false,
    },
    {
      key: 'role',
      value: t('group'),
      sortable: true,
    },
    {
      key: 'delete',
      value: '',
      sortable: false,
    },
  ];

  return (
    <>
      <Header title={t('user_administration')}>
        <SearchAndFilter onFilterChange={onFilterChange} onSearchTermChange={onSearchTermChange} pageType={'users'} />
      </Header>
      {isLoading ? (
        <div className='mt-5'>
          <LoadingSpinner isLoading={isLoading} />
        </div>
      ) : (
        <div className='my-5'>
          <div className='flex gap-4'>
            <Button className='bg-tr-blue flex mb-5' onClick={handleModalToggle}>
              {t('add_user')}
            </Button>
            <Button className='bg-tr-blue flex mb-5' onClick={exportData}>
              {t('export')}
            </Button>
          </div>

          <UserAdministrationTable
            columns={columns}
            entries={users.data}
            pagination={users.pagination}
            onPageChange={onPageChange}
            onSortBy={onSortBy}
            onUserChange={onUserChange}
          />
        </div>
      )}
      {isModalOpen && <AddUserModal onClose={handleModalToggle} isOpen={isModalOpen} onUserChange={onUserChange} />}
    </>
  );
};

export default UserAdministration;
