import React, { createContext, useState, useContext } from "react";
import attendanceService from "../services/attendance";
import ContextResponseModal from "../components/modals/ContextResponseModal";
import { useTranslation } from "react-i18next";

const AttendanceContext = createContext();

export const AttendanceProvider = ({ children }) => {
  const { t } = useTranslation();
  const [attendance, setAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({
    isOpen: false,
    error: false,
    message: "",
  });

  const toggleModal = (isModalOpen) => {
    setStatus({
      isOpen: !isModalOpen,
      error: false,
      message: "",
    });
  };

  const fetchAttendance = async (data) => {
    setIsLoading(true);
    try {
      const response = await attendanceService.getTimetracking(data);
      setAttendance({
        data: response.data.data,
        pagination: { ...response.data },
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const changeConfirmationStatus = async (entryId) => {
    setIsLoading(true);
    try {
      await attendanceService.changeConfirmationStatus(entryId);
      setStatus({
        isOpen: true,
        error: false,
        message: t("status_updated_successfully"),
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <AttendanceContext.Provider
        value={{
          attendance,
          fetchAttendance,
          isLoading,
          changeConfirmationStatus,
        }}
      >
        {children}
      </AttendanceContext.Provider>
      <ContextResponseModal
        status={status}
        isOpen={status.isOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export const useAttendance = () => useContext(AttendanceContext);

export default AttendanceProvider;
