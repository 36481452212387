import React, { useState } from "react";
import Icon from "../components/common/Icon";
import Button from "../components/common/Button";
import { useSearchParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const ChangePassword = () => {
  const { handleResetPassword } = UserAuth();
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const updatePassword = async (e) => {
    e.preventDefault();
    if (!password) {
      setError({
        status: true,
        message: "New password required!",
      });
      return;
    }
    if (password.length || confirmPassword.length) {
      if (password !== confirmPassword) {
        setError({
          status: true,
          message: "Passwords don't match!",
        });
        return;
      } else {
        setError({
          status: false,
          message: "",
        });
      }
    }
    if (searchParams.get("token")) {
      await handleResetPassword(password, searchParams.get("token"));
    }
  };

  return (
    <section className="flex mt-[10%] pb-12 justify-center px-6 lg:px-8 items-center">
      <div className="w-full max-w-md space-y-8 md:space-y-12">
        <div>
          <img
            className="mx-auto "
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="logo"
          />
          <h2 className="text-primary text-center text-2xl lg:text-3xl font-bold pt-16 pb-2">
            Change password!
          </h2>
          <h2 className="text-primary text-center text-md lg:text-xl font-light">
            Please enter new password.
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className=" space-y-6 rounded-md">
            <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
              <span className="px-3">
                <Icon name="lock" className="h-4 w-4 text-tr-gray" />
              </span>
              <input
                placeholder="New password"
                id="password"
                name="password"
                type="password"
                required
                className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
              <span className="px-3">
                <Icon name="lock" className="h-4 w-4 text-tr-gray" />
              </span>
              <input
                placeholder="Confirm password"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required
                className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <Button
            onClick={updatePassword}
            className="justify-center bg-tr-blue relative flex mx-auto w-full"
          >
            Update password
          </Button>
          {error.status && (
            <p className="text-error text-center">Bad input: {error.message}</p>
          )}
        </form>
      </div>
    </section>
  );
};

export default ChangePassword;
