import React, { useEffect, useState } from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";
import { PERMS } from "../../utils/constants";
import { UserAuth } from "../../context/AuthContext";
import { useOrganizations } from "../../context/OrganizationContext";

const AddOrganizationModal = ({
  isOpen,
  onClose,
  onOrganizationChange
}) => {
  const { t } = useTranslation();
  const [organizationInfo, setOrganizationInfo] = useState({
    schoolName: "",
    lat: "",
    lon: "",
    radius: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    shouldCheckLocation: false
  });
  const [error, setError] = useState({
    passwordError: false,
    formError: false
  });
  const { user } = UserAuth();
  const { addOrganization } = useOrganizations();

  const saveChanges = async () => {
    const { shouldCheckLocation, ...orgCheck } = organizationInfo;
    const checkError = (Object.values(orgCheck).every((item) => item !== ""))
    if (!checkError) {
      setError((prev) => ({
        ...prev,
        formError: true,
        passwordError: false
      }));
      return;
    } else {
      setError((prev) => ({
        ...prev,
        formError: false
      }))
    }
    if (organizationInfo.password.length || organizationInfo.confirmPassword.length) {
      if (organizationInfo.password !== organizationInfo.confirmPassword) {
        setError((prev) => ({
          ...prev,
          passwordError: true,
        }));
        return;
      } else {
        setError((prev) => ({
          ...prev,
          passwordError: false,
        }));
      }
    }
    const orgData = {
        schoolName: organizationInfo.schoolName,
        config: {
            coordinates: {
                lat: organizationInfo.lat,
                lon: organizationInfo.lon,
                radius: organizationInfo.radius
            },
            shouldCheckLocation: organizationInfo.shouldCheckLocation
        },
        user: {
            firstName: organizationInfo.firstName,
            lastName: organizationInfo.lastName,
            username: organizationInfo.username,
            email: organizationInfo.email,
            password: organizationInfo.password
        }
    }
    await addOrganization(orgData);
    onOrganizationChange();
    onClose(isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOrganizationInfo((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    setError({
      passwordError: false,
    });
  }, []);

  return (
    <Modal isOpen={isOpen} title={t("add_organization")} onClose={onClose}>
      <div className="flex flex-col gap-4">
        {user.role.permissions.includes(PERMS.MANAGE_ORGANIZATIONS) && (
        <>
            <p className="text-tr-gray-600 text-lg ml-2">{t("school_information")}</p>
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <input
                    id="schoolName"
                    placeholder={t("school_name")}
                    name="schoolName"
                    type="text"
                    value={organizationInfo.schoolName}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                />
            </div>
            <div className="flex gap-4">
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="lat"
                        placeholder={t("latitude")}
                        name="lat"
                        type="number"
                        value={organizationInfo.lat}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="lon"
                        placeholder={t("longitude")}
                        name="lon"
                        type="number"
                        value={organizationInfo.lon}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="radius"
                        placeholder={t("radius")}
                        name="radius"
                        type="number"
                        value={organizationInfo.radius}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>
            <div>
                <div className="flex items-center gap-2 ml-1">
                    <input
                        id="shouldCheckLocation"
                        type="checkbox"
                        name="shouldCheckLocation"
                        defaultChecked={organizationInfo.shouldCheckLocation}
                        onChange={handleInputChange}
                    />
                    <span className="text-primary">{t("check_location")}</span>
                </div>
            </div>
            <p className="text-tr-gray-600 text-lg ml-2">{t("initial_admin_information")}</p>
            <div className="flex gap-4">
                <div className="flex w-full items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="firstName"
                        placeholder={t("first_name")}
                        name="firstName"
                        type="text"
                        value={organizationInfo.firstName}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex w-full items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="lastName"
                        placeholder={t("last_name")}
                        name="lastName"
                        type="text"
                        value={organizationInfo.lastName}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <input
                    id="username"
                    placeholder={t("username")}
                    name="username"
                    type="text"
                    value={organizationInfo.username}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                />
            </div>  
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <input
                    id="email"
                    placeholder={t("email")}
                    name="email"
                    type="email"
                    value={organizationInfo.email}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                />
            </div>    
            <div className="flex gap-4">
                <div className="flex w-full items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="password"
                        placeholder={t("password")}
                        name="password"
                        type="password"
                        value={organizationInfo.password}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex w-full items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="confirmPassword"
                        placeholder={t("confirm_password_placeholder")}
                        name="confirmPassword"
                        type="password"
                        value={organizationInfo.confirmPassword}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>
            {error.passwordError && (
              <div className="w-full flex justify-center">
                <span className="text-error">{t("password_dont_match")}</span>
              </div>
            )}
            {error.formError && (
              <div className="w-full flex justify-center">
                <span className="text-error">{t("all_fields_required")}</span>
              </div>
            )}
        </>
        )}
        <div className="flex gap-4">
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={saveChanges}
          >
            {t("add_organization")}
          </Button>
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddOrganizationModal;
