import ClipLoader from 'react-spinners/ClipLoader';

const LoadingSpinner = ({ isLoading }) => {
  return (
    <ClipLoader
      color={'#424242'}
      loading={isLoading}
      cssOverride={{ display: 'block', margin: '0 auto' }}
      size={100}
      aria-label='Loading Spinner'
      data-testid='loader'
    />
  );
};

export default LoadingSpinner;
