import Icon from "./Icon";
import Pagination from "./Pagination";

const Header = ({ columns, onSortBy }) => {

  return (
    <thead className="bg-tr-light-gray text-sm leading-6">
      <tr className="border-b border-tr-medium-gray">
        {columns.map((column) => (
          <th key={column.key} className="h-16 px-4 text-left">
            <button
              className={`flex ${
                column.sortable ? "" : "disabled cursor-default"
              }`}
              disabled={!column.sortable}
              onClick={() => onSortBy(column.key)}
            >
              {column.value}
              {column.sortable && <Icon name="sort" className="text-tr-blue" />}
            </button>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Table = ({ children, columns, pagination, onPageChange, onSortBy }) => {
  return (
    <div className="w-full border border-tr-medium-gray rounded-[30px] overflow-auto bg-white">
      <table className="w-full">
        <Header columns={columns} onSortBy={onSortBy} />
        {children}
      </table>
      <div className="flex items-center justify-end p-5">
        {
            pagination &&
            <Pagination 
                currentPage={+pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={pagination.totalItems}
                onPageChange={onPageChange}
            />
        }
      </div>
    </div>
  );
};

export default Table;
