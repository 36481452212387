import { UserAuth } from "../../context/AuthContext";
import { DEFAULT_SCHOOL_HOURS } from "../../utils/constants";
import Icon from "../common/Icon";
import Switch from "../common/Switch";
import Table from "../common/Table";
import moment from "moment";
import { PERMS } from "../../utils/constants";

const ClassViewTable = ({
  initialEntries,
  columns,
  onSortBy,
  setSingleByod,
  schoolHours
}) => {

  const { user } = UserAuth();

  const getSchoolStartTime = (groupParam, dayParam) => {
    return schoolHours.filter(({name}) => name === groupParam).map((day) => day.schoolHours ? day.schoolHours[dayParam].from : DEFAULT_SCHOOL_HOURS[dayParam].from)[0]
  }

  return (
    <div className="overflow-x-auto">
      <Table columns={columns} onSortBy={onSortBy}>
        <tbody>
          {initialEntries &&
            initialEntries.map((entry) => (
              <tr
                key={entry.id}
                className={`border-b border-tr-medium-gray text-sm ${
                  entry.loggedIn ? "" : "bg-tr-light-red"
                }`}
              >
                {columns.map((column) => {
                  if (column.key === "isByodStatus") {
                    if (user.role.permissions.includes(PERMS.MANAGE_BYOD)) {
                      return (
                        <td key={column.key} className="h-12 px-4">
                          <Switch
                            isOn={entry.isByodStatus}
                            isLoggedIn={entry.loggedIn}
                            onToggle={(e) => setSingleByod(entry.id)}
                          />
                        </td>
                      );
                    } else {
                      return (
                        <td key={column.key} className="h-12 px-4">
                          /
                        </td>
                      );
                    }
                  } else if (column.key === "loggedIn") {
                    return (
                      <td key={column.key} className="h-12 px-4">
                        <div
                          className={`w-3 h-3 rounded-full mx-auto ${
                            entry[column.key] ? "bg-tr-green" : "bg-tr-red"
                          }`}
                        ></div>
                      </td>
                    );
                  } else {
                    return (
                      <td key={column.key} className="h-12 px-4 text-left">
                        <div className="flex gap-2 items-center text-tr-gray-600">
                          {column.key === "arrivalTime"
                            ? entry[column.key]
                              ? moment(entry[column.key]).format("HH:mm")
                              : "/"
                            : entry[column.key]}
                          {column.key === "arrivalTime" &&
                            entry[column.key] &&
                            moment(entry[column.key]).format("HH:mm") >
                            getSchoolStartTime(entry.classGroups[0], moment(entry[column.key]).locale("en").format('dddd').toLowerCase()) &&
                            (
                              <Icon name="error" className="text-tr-yellow" />
                            )}
                          {column.key === "firstName" &&
                            entry.isRequestedPassReset && (
                              <Icon
                                name="exclamationTriangle"
                                className="text-tr-red"
                                title="User requested password change"
                              />
                            )}
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ClassViewTable;
