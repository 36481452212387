import { useState } from "react";
import Icon from "../common/Icon";
import Table from "../common/Table";
import { PERMS, ROLE_NAMES } from "../../utils/constants";
import DeleteUserModal from "../modals/DeleteUserModal";
import EditUserModal from "../modals/EditUserModal";
import { useTranslation } from "react-i18next";
import { UserAuth } from '../../context/AuthContext';

const UserAdministrationTable = ({
  entries,
  columns,
  pagination,
  onPageChange,
  onSortBy,
  onUserChange,
}) => {
  const { t } = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");
  const [newUser, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    classGroupIds: "",
  });

  const { user } = UserAuth();
  const toggleEditModal = (isModalOpen, entry) => {
    if (!isModalOpen) {
      setUserId(entry.id);
      setUserRole(entry.role);
      setUser({
        firstName: entry.firstName,
        lastName: entry.lastName,
        email: entry.email,
        classGroupIds: entry.classGroups,
        isRemote: entry.isRemote,
        role: entry.role
      });
    }
    setIsEditModalOpen(!isModalOpen);
  };
  const toggleDeleteModal = (isModalOpen, entry) => {
    if (!isModalOpen) {
      setUserId(entry.id);
    }
    setIsDeleteModalOpen(!isModalOpen);
  };
  return (
    <>
      <Table
        columns={columns}
        pagination={pagination}
        onPageChange={onPageChange}
        onSortBy={onSortBy}
      >
        <tbody>
          {entries &&
            entries.map((entry) => (
              <tr
                key={entry.id}
                className="border-b border-tr-medium-gray text-sm"
              >
                {columns.map((column) => {
                  if (column.key === "classGroups") {
                    return (
                      <td
                        key={column.key}
                        className="h-12 px-4 text-left text-tr-gray-600"
                      >
                        <div>
                          {entry["role"] === ROLE_NAMES.SCHOOL_ADMIN ||
                          entry["role"] === ROLE_NAMES.TEACHER ||
                          entry["role"] === ROLE_NAMES.TR_ADMIN ||
                          entry["role"] === ROLE_NAMES.ORG_ADMIN ? (
                            <Icon
                              name="statusCircle"
                              className={`${
                                entry["role"] === ROLE_NAMES.SCHOOL_ADMIN
                                  ? "text-tr-blue"
                                  : entry["role"] === ROLE_NAMES.TEACHER
                                  ? "text-tr-orange"
                                  : entry["role"] === ROLE_NAMES.TR_ADMIN
                                  ? "text-tr-red"
                                  : entry["role"] === ROLE_NAMES.ORG_ADMIN
                                  ? "text-tr-admin"
                                  : ""
                              }`}
                            />
                          ) : entry[column.key].length ? (
                            entry[column.key]
                          ) : (
                            "/"
                          )}
                        </div>
                      </td>
                    );
                  } else if (column.key === "role") {
                    return (
                      <td
                        key={column.key}
                        className="h-12 px-4 text-left text-tr-gray-600"
                      >
                        {entry[column.key] === ROLE_NAMES.TEACHER ? (
                          <div className="flex">
                            <div className="bg-tr-light-orange flex items-center gap-1 px-2 py-1 rounded-[50px]">
                              <Icon
                                name="teacher"
                                className="text-tr-gray-600"
                              />
                              <p>{t("teacher")}</p>
                            </div>
                          </div>
                        ) : entry[column.key] === ROLE_NAMES.SCHOOL_ADMIN ? (
                          <div className="flex">
                            <div className="bg-tr-light-blue flex items-center gap-1 px-2 py-1 rounded-[50px]">
                              <Icon
                                name="school"
                                className="text-tr-gray-600"
                              />
                              <p>{t("school_administrator")}</p>
                            </div>
                          </div>
                        ) : entry[column.key] === ROLE_NAMES.ORG_ADMIN ? (
                          <div className="flex">
                            <div className="bg-tr-light-admin flex items-center gap-1 px-2 py-1 rounded-[50px]">
                              <Icon
                                name="administration"
                                className="text-tr-gray-600"
                              />
                              <p>{t("administration")}</p>
                            </div>
                          </div>
                        ) : entry[column.key] === ROLE_NAMES.TR_ADMIN ? (
                          <div className="flex">
                            <div className="bg-tr-light-red flex items-center gap-1 px-2 py-1 rounded-[50px]">
                              <Icon
                                name="trAdmin"
                                className="text-tr-gray-600"
                              />
                              <p>{t("time_river_admin")}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            <div className="bg-tr-light-green flex items-center gap-1 px-2 py-1 rounded-[50px]">
                              <Icon
                                name="student"
                                className="text-tr-gray-600"
                              />
                              <p>{t("student")}</p>
                            </div>
                          </div>
                        )}
                      </td>
                    );
                  } else if (column.key === "action") {
                    return (
                      <td key={column.key} className="h-12 px-4 text-left">
                        <div className="flex items-center gap-1">
                          <button
                            onClick={(prev) => toggleEditModal(!prev, entry)}
                          >
                            <Icon
                              name="settings"
                              className="text-tr-gray-600"
                            />
                          </button>
                          <p className="text-tr-gray-600">{t("edit")}</p>
                        </div>
                      </td>
                    );
                  } else if (column.key === "delete" && user.role.permissions.includes(PERMS.MANAGE_USERS)) {
                    return (
                       <td key={column.key} className="px-2">
                        <button
                          className="flex items-center justify-center"
                          onClick={(prev) => toggleDeleteModal(!prev, entry)}
                        >
                          <Icon name="delete" className="text-tr-gray-600" />
                        </button>
                      </td>
                    );
                  } else {
                    return (
                      <td key={column.key} className="h-12 px-4 text-left">
                        <div className="flex gap-2 items-center text-tr-gray-600">
                          {column.key === "firstName" &&
                            entry.isRequestedPassReset && (
                              <Icon
                                name="exclamationTriangle"
                                className="text-tr-gray-600 text-tr-red"
                                title="User requested password change"
                              />
                            )}
                          {entry[column.key]}
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </Table>
      {isEditModalOpen && <EditUserModal
        isOpen={isEditModalOpen}
        onClose={toggleEditModal}
        onUserChange={onUserChange}
        passedUser={newUser}
        selectedUserId={userId}
        selectedUserRole={userRole}
      />}
      <DeleteUserModal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        onUserChange={onUserChange}
        userId={userId}
      />
    </>
  );
};

export default UserAdministrationTable;
