
import axiosWithAuth from './axiosWithAuth';

const getQueryString = (queries) => {
  return Object.keys(queries).reduce((result, key) => {
      return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`]
  }, []).join('&');
};

const getAllUsers = async (filterData) => {
  let initialUrl = '/users';
  if (filterData) {
    initialUrl = initialUrl + `?${getQueryString(filterData)}`;
  }
  return await axiosWithAuth.get(initialUrl);
};

const exportUsers = async (filterData) => {
  let initialUrl = '/users/export';
  delete filterData.currentPage;
  if (filterData) {
    initialUrl = initialUrl + `?${getQueryString(filterData)}`;
  }
  return await axiosWithAuth.get(initialUrl);
};

const getUserById = async (id) => {
  return await axiosWithAuth.get(`/users/${id}`);
};

const editUserById = async (id, data) => {
  return await axiosWithAuth.put(`/users/${id}`, data);
}

const deleteUserById = async (id) => {
  return await axiosWithAuth.delete(`/users/${id}`);
}

const getLoggedUser = async () => {
  return await axiosWithAuth.get('/users/me');
}

const addUsersFromFile = async (usersData) => {
  return await axiosWithAuth.post('/users', usersData);
};

export default {
  getAllUsers,
  exportUsers,
  getUserById,
  getLoggedUser,
  addUsersFromFile,
  editUserById,
  deleteUserById
};