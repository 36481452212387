import React, { useCallback, useRef } from "react";
import Header from "../components/common/Header";
import Icon from "../components/common/Icon";
import Papa from "papaparse";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useUsers } from "../context/UsersContext";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const Import = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { addUsers, isLoading } = useUsers();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      handleFile(file);
    }
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: { "text/csv": [".csv"] },
  });

  const handleFileSelectionClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        console.log(results.data);
        addUsersFromFile(results.data);
      },
      header: true,
    });
  };

  const addUsersFromFile = async (data) => {
    try {
      await addUsers(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <Header title={t("import_students")} />
      <div
        className="flex-grow flex justify-center items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <div {...getRootProps()}>
          <div className="bg-white border border-dashed border-tr-medium-gray rounded-2xl px-20 sm:px-40 py-10 sm:py-20 flex flex-col items-center text-xs">
            <Icon
              name="documentArrowUp"
              className="w-10 mb-4 text-tr-blue cursor-pointer"
              onClick={handleFileSelectionClick}
            />
            <p className="text-center text-lg">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {t("drop_instruction")}{" "}
                  <span
                    className="text-tr-blue cursor-pointer"
                    onClick={handleFileSelectionClick}
                  >
                    {t("browse")}
                  </span>
                  <br />
                  <span className="text-light text-[10px]">
                    {t("file_support_note")}
                  </span>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
