import React, { useEffect, useState } from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";
import { PERMS } from "../../utils/constants";
import { UserAuth } from "../../context/AuthContext";
import { useOrganizations } from "../../context/OrganizationContext";

const EditOrganizationModal = ({
  isOpen,
  onClose,
  passedSchool,
  selectedSchoolId,
  onOrganizationChange
}) => {
  const { t } = useTranslation();
  const [organizationId, setOrganizationId] = useState("");
  const [organizationInfo, setOrganizationInfo] = useState({
    schoolName: "",
    lat: "",
    lon: "",
    radius: "",
    shouldCheckLocation: false
  });
  const { user } = UserAuth();
  const { editOrganization } = useOrganizations();
  const [error, setError] = useState({
    formError: false
  });

  const saveChanges = async () => {
    if (!organizationInfo.schoolName.length) {
      setError((prev) => ({
        ...prev,
        formError: true
      }));
      return;
    } else {
      setError((prev) => ({
        ...prev,
        formError: false
      }))
    }
    const orgData = {
        schoolName: organizationInfo.schoolName,
        config: {
            coordinates: {
                lat: organizationInfo.lat,
                lon: organizationInfo.lon,
                radius: organizationInfo.radius
            },
            shouldCheckLocation: organizationInfo.shouldCheckLocation
        }
    }
    await editOrganization(organizationId, orgData);
    onOrganizationChange();
    onClose(isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOrganizationInfo((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    setOrganizationId(selectedSchoolId);
    setOrganizationInfo(() => ({
      ...passedSchool,
    }));
  }, [passedSchool]);

  return (
    <Modal isOpen={isOpen} title={t("edit_organization")} onClose={onClose}>
      <div className="flex flex-col gap-4">
        {user.role.permissions.includes(PERMS.MANAGE_ORGANIZATIONS) && (
        <>
            <div>
                <label className="ml-2 text-tr-gray-500">{t("school_name")}:</label>
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <input
                        id="schoolName"
                        placeholder={t("school_name")}
                        name="schoolName"
                        type="text"
                        value={organizationInfo.schoolName}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <div>
                    <label className="ml-2 text-tr-gray-500">{t("latitude")}:</label>
                    <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                        <input
                            id="lat"
                            placeholder={t("latitude")}
                            name="lat"
                            type="number"
                            value={organizationInfo.lat}
                            onChange={handleInputChange}
                            required
                            className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                        />
                    </div>
                </div>
                <div>
                    <label className="ml-2 text-tr-gray-500">{t("longitude")}:</label>
                    <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                        <input
                            id="lon"
                            placeholder={t("longitude")}
                            name="lon"
                            type="number"
                            value={organizationInfo.lon}
                            onChange={handleInputChange}
                            required
                            className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                        />
                    </div>
                </div>
                <div>
                    <label className="ml-2 text-tr-gray-500">{t("radius")}:</label>
                    <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                        <input
                            id="radius"
                            placeholder={t("radius")}
                            name="radius"
                            type="number"
                            value={organizationInfo.radius}
                            onChange={handleInputChange}
                            required
                            className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="flex items-center gap-2 ml-1">
                    <input
                        id="shouldCheckLocation"
                        type="checkbox"
                        name="shouldCheckLocation"
                        defaultChecked={passedSchool.shouldCheckLocation}
                        onChange={handleInputChange}
                    />
                    <span className="text-primary">{t("check_location")}</span>
                </div>
            </div>
            {error.formError && (
              <div className="w-full flex justify-center">
                <span className="text-error">{t("school_name_required")}</span>
              </div>
            )}
        </>
        )}
        <div className="flex gap-4">
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={saveChanges}
          >
            {t("edit_organization")}
          </Button>
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditOrganizationModal;
