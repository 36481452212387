import React, { createContext, useState, useContext } from 'react';
import classesService from '../services/classes';
import ContextResponseModal from '../components/modals/ContextResponseModal';
import { useTranslation } from 'react-i18next';

const ClassesContext = createContext();

export const ClassesProvider = ({ children }) => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState('');
  const [schoolHours, setSchoolHours] = useState(null);
  const [students, setStudents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({
    isOpen: false,
    error: false,
    message: '',
  });

  const toggleModal = (isModalOpen) => {
    setStatus({
      isOpen: !isModalOpen,
      error: false,
      message: '',
    });
  };

  const changeActiveClasses = async (data) => {
    setSelectedClasses(data.join(','));
  };

  const fetchClassesForEdit = async () => {
    setIsLoading(true);
    try {
      const response = await classesService.getClasses();
      setSelectedClasses(response.data[0].name);
      setClasses(response.data.map((item) => item.name));
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const fetchClassesForSchoolHours = async () => {
    setIsLoading(true);
    try {
      const response = await classesService.getClasses();
      if (sessionStorage.getItem('hourClasses')) {
        setSelectedClasses(sessionStorage.getItem('hourClasses'));
      } else {
        setSelectedClasses(response.data[0].name);
        sessionStorage.setItem('hourClasses', response.data[0].name);
      }
      setClasses(response.data.map((item) => item.name));
      setSchoolHours(response.data);
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const fetchClassesList = async () => {
    setIsLoading(true);
    try {
      const response = await classesService.getClasses();
      if (response.data.length === 0) {
        setIsLoading(false);
      } else {
        if (sessionStorage.getItem('classes')) {
          setSelectedClasses(sessionStorage.getItem('classes'));
        } else {
          setSelectedClasses(response.data[0].name);
          sessionStorage.setItem('classes', response.data[0].name);
        }
        setClasses(response.data.map((item) => item.name));
        setSchoolHours(response.data);
        const getStudents = await classesService.getClassView(sessionStorage.getItem('classes'));
        setStudents(getStudents.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const fetchStudents = async (filters) => {
    if (selectedClasses) {
      setIsLoading(true);
      try {
        const getStudents = await classesService.getClassView(selectedClasses, filters);
        setStudents(getStudents.data.data);
        console.log(getStudents);
        setIsLoading(false);
      } catch (error) {
        setStatus({
          isOpen: true,
          error: true,
          message: error.response.data.message,
        });
        setIsLoading(false);
      }
    }
  };

  const changeByodStatus = async (selection, isMultySelection) => {
    setIsLoading(true);
    let selectionData = {};
    const userIdArray = [];
    if (isMultySelection !== undefined) {
      selection.forEach((item) => {
        if (item.loggedIn) {
          userIdArray.push(item.id);
        }
      });
      selectionData = {
        userIds: userIdArray,
        isByod: isMultySelection,
      };
    } else {
      selectionData = {
        userIds: [selection],
      };
    }

    try {
      await classesService.setByodStatus(selectionData);
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const changeSchoolHours = async (id, hoursData) => {
    setIsLoading(true);
    try {
      await classesService.setSchoolHours(id, hoursData);
      setStatus({
        isOpen: true,
        error: false,
        message: t('hours_updated_successfully'),
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ClassesContext.Provider
        value={{
          isLoading,
          fetchStudents,
          students,
          fetchClassesList,
          changeByodStatus,
          classes,
          selectedClasses,
          changeActiveClasses,
          fetchClassesForEdit,
          fetchClassesForSchoolHours,
          schoolHours,
          changeSchoolHours,
        }}
      >
        {children}
      </ClassesContext.Provider>
      <ContextResponseModal status={status} isOpen={status.isOpen} onClose={toggleModal} />
    </>
  );
};

export const useClasses = () => useContext(ClassesContext);

export default ClassesProvider;
