import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import Icon from "../common/Icon";
import { useTranslation } from "react-i18next";
import { useClasses } from "../../context/ClassesContext";

const ChangeClassModal = ({ onDone, isModalOpen, isMultySelect }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeClasses, setActiveClasses] = useState([]);
  const { changeActiveClasses, selectedClasses, classes } = useClasses();

  const selectClasses = async (e) => {
    if (isMultySelect) {
      if (e.target.checked) {
        setActiveClasses((prev) => [...prev, e.target.value]);
      } else {
        const filteredClasses = activeClasses.filter(
          (item) => item !== e.target.value
        );
        setActiveClasses(filteredClasses);
      }
    } else if (!isMultySelect) {
      if (e.target.checked) {
        await changeActiveClasses([e.target.value]);
        sessionStorage.setItem('hourClasses', e.target.value)
      }
    }
  };

  const callApi = async () => {
    if (activeClasses.length && isMultySelect) {
      await changeActiveClasses(activeClasses);
      sessionStorage.setItem('classes', activeClasses.join(","))
    }
    onDone();
  };

  const checkClassSelection = (classList, currentClass) => {
    let isSelected = false;
    if (classList.includes(",")) {
      const classesArray = classList.split(",");
      classesArray.forEach(item => {
        if (item === currentClass) isSelected = true;
      })
    } else {
      if (classList === currentClass) isSelected = true;
    }
    return isSelected
  }

  useEffect(() => {
    if (selectedClasses?.includes(",")) {
      setActiveClasses(selectedClasses.split(","));
    } else {
      setActiveClasses([selectedClasses]);
    }
  }, [selectedClasses]);

  return (
    <Modal
      title={
        <span className="flex items-center text-base">
          <Icon name="arrowsUpDown" className="mr-2 rotate-90 w-3.5" />
          {t("change_class")}
        </span>
      }
      isOpen={isModalOpen}
      onClose={onDone}
    >
      <div className="h-[394px] p-2 min-w-[290px]">
        <div>
          <input
            type="search"
            placeholder={t("search_class")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 p-2 border border-tr-medium-gray rounded-2xl text-xs w-full focus:outline-none focus:ring focus:ring-blue-500"
          />
          <Icon
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-tr-gray"
            name="magnifyingGlassCircle"
          />
        </div>

        <div className="overflow-auto h-full max-h-[280px] mb-4 custom-scrollbar pl-6">
          {classes &&
            classes
              .filter((cls) =>
                cls.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((classItem) => {
                return isMultySelect ? (
                  <div key={classItem} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      defaultChecked={checkClassSelection(selectedClasses, classItem)}
                      id={classItem}
                      className="mr-2 cursor-pointer"
                      value={classItem}
                      onChange={(e) => selectClasses(e)}
                    />
                    <label htmlFor={classItem}>{classItem}</label>
                  </div>
                ) : (
                  <div key={classItem} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={selectedClasses === classItem}
                      id={classItem}
                      className="mr-2 cursor-pointer"
                      value={classItem}
                      onChange={(e) => selectClasses(e)}
                    />
                    <label htmlFor={classItem}>{classItem}</label>
                  </div>
                );
              })}
        </div>
        <Button className="bg-tr-blue w-full" onClick={callApi}>
          {t("done")}
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeClassModal;
