import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { getToken } from "../services/auth";
import LanguageSwitcher from "./LanguageSwitcher";

const ProtectedIfLoggedRoute = ({ children }) => {
  const { user } = UserAuth();
  if (getToken()) {
    return <Navigate to="/" />;
  } else if (!user) {
    return (
      <>
        <div className="flex justify-end m-2">
          <LanguageSwitcher />
        </div>
        {children}
      </>
    );
  }
};

export default ProtectedIfLoggedRoute;
