import Navbar from "./common/Navbar";
import { useTranslation } from "react-i18next";

const ConfirmEmail = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar isLogoutOnly={true} />
      <section className="!mb-24">
        <p className="mb-8">{t("thank_you")}</p>
        <p>{t("confirmation_notice")}</p>
      </section>
    </>
  );
};

export default ConfirmEmail;
