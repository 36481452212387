import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [organization, setOrganization] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [error, setError] = useState("");

  const { handleLogin } = UserAuth();

  const onLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await handleLogin(username, organization, password, isRememberMe);
      navigate("/");
    } catch (e) {
      setError(e.message);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      <section className="flex mt-[10%] pb-12 justify-center px-4 lg:px-8 items-center">
        <div className="w-full max-w-md space-y-8 md:space-y-12">
          <div>
            <img
              className="mx-auto "
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="logo"
            />
            <h2 className="text-primary text-center text-2xl lg:text-3xl font-bold pt-16 pb-2">
              {t("welcome_back")}
            </h2>
            <h2 className="text-primary text-center text-md lg:text-xl font-light">
              {t("please_log_in")}
            </h2>
          </div>
          <form className="mt-8 space-y-6">
            <div className=" space-y-6 rounded-md">
              <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
                <span className="px-3">
                  <Icon name="organization" className="h-4 w-4 text-tr-gray" />
                </span>
                <input
                  id="organization"
                  placeholder={t("organization")}
                  name="organization"
                  type="text"
                  required
                  className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </div>
              <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
                <span className="px-3">
                  <Icon name="user" className="h-4 w-4 text-tr-gray" />
                </span>
                <input
                  placeholder={t("username")}
                  name="uname"
                  type="text"
                  required
                  className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
                <span className="px-3">
                  <Icon name="lock" className="h-4 w-4 text-tr-gray" />
                </span>
                <input
                  id="password"
                  placeholder={t("password")}
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="px-3" onClick={togglePasswordVisibility}>
                  <Icon
                    name={isPasswordVisible ? "eyeSlash" : "eye"}
                    className="h-4 w-4 text-tr-gray cursor-pointer hidden sm:block"
                  />
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center pt-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox text-blue-500 rounded"
                  onChange={(e) => setIsRememberMe(e.target.checked)}
                />
                <span className="ml-2 text-sm text-primary">
                  {t("rememeber_me")}
                </span>
              </label>
              <a href="/reset-password" className="text-sm text-tr-blue">
                {t("forgot_password")}
              </a>
            </div>

            <Button
              onClick={onLogin}
              className="justify-center bg-tr-blue relative flex mx-auto w-full"
            >
              {t("login")}
            </Button>
            {error && <p className="text-error text-center">{t("badLogin")}</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
