import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import ConfirmEmail from "./ConfirmEmail";
import { getToken } from "../services/auth";
import Layout from "./Layout";

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();
  if (!getToken() && !user) {
    return <Navigate to="/login" />;
  } else if (user?.hasOwnProperty("emailVerified") && !user.emailVerified) {
    return <ConfirmEmail />;
  } else if (user?.username) {
    return <Layout> {children} </Layout>;
  }
};

export default ProtectedRoute;
