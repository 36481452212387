import React from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { useUsers } from "../../context/UsersContext";
import { useTranslation } from "react-i18next";

const DeleteUserModal = ({ isOpen, onClose, userId, onUserChange }) => {
  const { t } = useTranslation();
  const { deleteUser } = useUsers();

  const deleteAndClose = async () => {
    await deleteUser(userId);
    onUserChange();
    onClose(isOpen);
  };

  return (
    <>
      <Modal isOpen={isOpen} title={t("delete_selected_user")} onClose={onClose}>
        <p className="p-1 text-tr-gray">{t("are_you_sure")}</p>
        <div className="flex items-center justify-between">
          <Button
            className="justify-center bg-tr-blue relative flex w-20 mt-4"
            onClick={deleteAndClose}
          >
            {t("yes")}
          </Button>
          <Button
            className="justify-center bg-tr-blue relative flex w-20 mt-4"
            onClick={onClose}
          >
            {t("no")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
