import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { NavLink } from "react-router-dom";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState("");
  const { handleRequestPasswordReset } = UserAuth();

  const onResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    if (!email.length || !organization.length) {
      setError("Both fields are required!");
      return;
    }
    await handleRequestPasswordReset(email, organization);
    setIsFormSubmitted(true);
  };

  return (
    <>
      <section className="flex mt-[10%] pb-12 justify-center px-6 lg:px-8 items-center">
        <div className="w-full max-w-md space-y-8 md:space-y-12">
          <div>
            <img
              className="mx-auto "
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="logo"
            />
            <h2 className="text-primary text-center text-2xl lg:text-3xl font-bold pt-16 pb-2">
              Forgot password?
            </h2>
            <h2 className="text-primary text-center text-md lg:text-xl font-light">
              Please enter your email to receive link for password reset.
            </h2>
          </div>
          {!isFormSubmitted && (
            <form className="mt-8 space-y-6">
              <div className="space-y-6 rounded-md">
                <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
                  <span className="px-3">
                    <Icon name="user" className="h-4 w-4 text-tr-gray" />
                  </span>
                  <input
                    id="email-address"
                    placeholder="Email"
                    name="email"
                    type="email"
                    required
                    className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-6 rounded-md">
                <div className="flex items-center border border-tr-medium-gray rounded-2xl bg-white">
                  <span className="px-3">
                    <Icon
                      name="organization"
                      className="h-4 w-4 text-tr-gray"
                    />
                  </span>
                  <input
                    id="organization"
                    placeholder="Organization"
                    name="organization"
                    type="text"
                    required
                    className="flex-1 text-sm appearance-none rounded-2xl border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </div>
              </div>
              <Button
                onClick={onResetPassword}
                className="justify-center bg-tr-blue relative flex mx-auto w-full"
              >
                Send link
              </Button>
              {error && <p className="text-error text-center">{error}</p>}
            </form>
          )}
          {isFormSubmitted && (
            <p className="!mt-12 text-center text-tr-gray-600">
              Instructions on how to reset your password have been sent to your
              email.
            </p>
          )}
          <p className="text-base text-light text-center !mt-12 cursor-default">
            Remember your password?{" "}
            <NavLink to="/login" className="text-primary hover:opacity-80">
              Login
            </NavLink>
          </p>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
