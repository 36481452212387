import { UserAuth } from "../context/AuthContext";

const PermissionsRouteCheck = ({ children, requiredPermissions }) => {
    const { user } = UserAuth();
    if (requiredPermissions.length && requiredPermissions.some(perm => user.role.permissions.includes(perm))) {
        return children;
    } else if (!requiredPermissions.length) {
        return children;
    } else {
        return
    }
};

export default PermissionsRouteCheck;
