import axios from "axios";
import { getToken } from "./auth";
import { jwtDecode } from "jwt-decode";

const axiosWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosWithAuth.interceptors.request.use(
  (config) => {
    const token = getToken();
    const tokenDecoded = jwtDecode(token);
    const tenantId = tokenDecoded.tenantId;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["x-tenant-id"] = tenantId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosWithAuth;
