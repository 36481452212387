import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import AttendanceTable from "../components/tables/AttendanceTable";
import SearchAndFilter from "../components/common/SearchAndFilter";
import { useAttendance } from "../context/AttendanceContext";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useTranslation } from "react-i18next";

const Attendance = () => {
  const { t } = useTranslation();
  const { attendance, fetchAttendance, isLoading, changeConfirmationStatus } =
    useAttendance();
  const [filtering, setFiltering] = useState();

  const onPageChange = (page) => {
    setFiltering((prev) => ({
      ...prev,
      currentPage: page,
    }));
  };

  const onSortBy = (sortKey) => {
    let checkSortKey = false;
    if (filtering?.sortBy === sortKey) {
      checkSortKey = true;
    }
    setFiltering((prev) => ({
      ...prev,
      currentPage: 1,
      sortBy: `${sortKey}`,
      sortDirection: `${
        checkSortKey ? (prev.sortDirection === "DESC" ? "ASC" : "DESC") : "DESC"
      }`,
    }));
  };

  const onFilterChange = (filterValues) => {
    setFiltering((prev) => ({
      ...prev,
      ...filterValues,
      currentPage: 1
    }));
  };

  const changeStatus = async (entryId) => {
    await changeConfirmationStatus(entryId);
    setFiltering((prev) => ({
      ...prev,
    }));
  };

  const onSearchTermChange = (term) => {
    setFiltering((prev) => ({
      ...prev,
      searchTerm: term,
      currentPage: 1
    }));
  };

  useEffect(() => {
    fetchAttendance(filtering);
  }, [filtering]);

  const columns = [
    {
      key: "firstName",
      value: t("first_name"),
    },
    {
      key: "lastName",
      value: t("last_name"),
      sortable: true,
    },
    {
      key: "classGroups",
      value: t("class"),
      sortable: true,
    },
    {
      key: "date",
      value: t("date"),
      sortable: false,
    },
    {
      key: "checkIn",
      value: t("login_time"),
      sortable: true,
    },

    {
      key: "checkOut",
      value: t("logout_time"),
      sortable: false,
    },
    {
      key: "isConfirmed",
      value: t("time_entry"),
      sortable: false,
    },
  ];

  return (
    <div>
      <Header title={t("attendance")}>
        <SearchAndFilter
          onFilterChange={onFilterChange}
          onSearchTermChange={onSearchTermChange}
          pageType={"attendance"}
        />
      </Header>
      <div>
        {isLoading ? (
          <div className="mt-5">
            <LoadingSpinner isLoading={isLoading} />
          </div>
        ) : (
          <div className="my-5">
            <AttendanceTable
              columns={columns}
              entries={attendance.data}
              pagination={attendance.pagination}
              onPageChange={onPageChange}
              onSortBy={onSortBy}
              changeStatus={changeStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Attendance;
