import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const register = async (email, password) => {
  return await axios.post(`${API_URL}/auth/register`, { email, password });
};

const login = async (username, organization, password, isRememberMe) => {
  const tenantId = organization.toLowerCase().replace(/\s+/g, "");
  return await axios.post(
    `${API_URL}/auth/login`,
    {
      username,
      password,
      isRememberMe,
    },
    {
      headers: {
        "x-tenant-id": tenantId,
      },
    }
  );
};

const requestPasswordReset = async (email, organization) => {
  const tenantId = organization.toLowerCase().replace(/\s+/g, "");
  return await axios.post(`${API_URL}/auth/requestPasswordReset`, { email }, { headers: { "x-tenant-id": tenantId }});
};

const resetPassword = async (password, token) => {
  const parts = token.split(':');
  const encodedOrgName = parts[0];
  const tenantId = (atob(encodedOrgName));
  return await axios.post(`${API_URL}/auth/resetPassword`, { password, token }, { headers: { "x-tenant-id": tenantId }});
}

const sendConfirmationEmail = async (user) => {
  return await axios.post(`${API_URL}/auth/send-confirmation`, { user });
};

export const getToken = () => {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
};

export default {
  register,
  login,
  requestPasswordReset,
  resetPassword,
  sendConfirmationEmail,
};
