import React from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";

const ContextResponseModal = ({ isOpen, onClose, status }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        isOpen={isOpen}
        title={`${status.error ? t("error_occurred") : t("success")}`}
        onClose={onClose}
      >
        <p className="p-1 text-tr-gray">{t(status.message)}</p>
        <Button
          className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
          onClick={onClose}
        >
          {t("ok")}
        </Button>
      </Modal>
    </>
  );
};

export default ContextResponseModal;
