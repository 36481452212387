import {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import authService, { getToken } from "../services/auth";
import userService from "../services/users";
import { PERMS } from "../utils/constants";
import ContextResponseModal from "../components/modals/ContextResponseModal";
import { useTranslation } from "react-i18next";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(getToken());
  const [status, setStatus] = useState({
    isOpen: false,
    error: false,
    message: ""
  });

  const toggleModal = (isModalOpen) => {
    setStatus({
      isOpen: !isModalOpen,
      error: false,
      message: ""
    })
  };

  const rehydrateUser = async () => {
    const storedToken = getToken();
    if (storedToken) {
      setToken(storedToken);
      try {
        const userData = await userService.getLoggedUser();
        if (userData.data.role.permissions.includes(PERMS.BACKEND_ACCESS)) {
          setUser(userData.data);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error(error);
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        setToken(null);
        setUser(null);
      }
    }
  };

  useEffect(() => {
    rehydrateUser();
  }, []);

  const handleLogin = async (username, organization, password, isRememberMe) => {
    const response = await authService.login(username, organization, password, isRememberMe);
    const { token } = response.data;
    if (isRememberMe) localStorage.setItem("token", token);
    else sessionStorage.setItem("token", token);
    await rehydrateUser();
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("classes")
    sessionStorage.removeItem("hourClasses")
    setToken(null);
    setUser(null);
  };

  const handleRequestPasswordReset = async (email, organization) => {
    try {
      await authService.requestPasswordReset(email, organization);
    } catch (error) {
      setStatus({
        isOpen: false,
        error: true,
        message: error.response.data.message
      })
    }
  };

  const handleResetPassword = async (password, token) => {
    try {
      await authService.resetPassword(password, token);
      setStatus({
        isOpen: true,
        error: false,
        message: t("password_changed_successfully")
      })
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message
      })
    }
  }

  const handleSendConfirmationEmail = async (user) => {
    //const response = await authService.sendConfirmationEmail(user);
    // TODO
  };

  // useEffect(() => {
  //   // Optionally, check token validity on mount and retrieve user data
  //   const verifyToken = async () => {
  //     if (token) {
  //       // Replace with your token verification endpoint
  //       const response = await axios.get('/api/auth/verifyToken', {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //       setUser(response.data.user);
  //     }
  //   };

  //   verifyToken();
  // }, [token]);

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          token,
          handleLogout,
          handleLogin,
          handleRequestPasswordReset,
          handleResetPassword,
          handleSendConfirmationEmail,
        }}
      >
        {children}
      </UserContext.Provider>
      <ContextResponseModal status={status} isOpen={status.isOpen} onClose={toggleModal} />
    </>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
