import React from "react";
import Icon from "./Icon";

const range = (start, end) => {
    return [...Array(end - start).keys()].map((el) => el + start);
}

const returnPagesArray = ({currentPage, pagesCount}) => {
    const leftIndex = Math.max(currentPage - 1, 1);
    const rightIndex = Math.min(currentPage + 1, pagesCount);
    const showLeftDots = leftIndex > 2;
    const showRightDots = rightIndex < pagesCount - 1;

    if (pagesCount <= 7) {
        return range(1, pagesCount + 1);
    } else if (!showLeftDots && showRightDots) {
        const leftRange = range(1, 5);
        return [...leftRange, " ...", pagesCount];
    } else if (showLeftDots && !showRightDots) {
        const rightRange = range(pagesCount - 3, pagesCount + 1);
        return [1, "... ", ...rightRange]
    } else {
        const middleRange = range(leftIndex, rightIndex + 1);
        return [1, "... ", ...middleRange, " ...", pagesCount]
    }
}

const Pagination = ({currentPage, totalItems, itemsPerPage, onPageChange}) => {
    const pagesCount = Math.ceil(totalItems / itemsPerPage);
    let pages = returnPagesArray({currentPage, pagesCount});

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === pagesCount;
    return (
        <ul className="flex items-center">
            <li
                className={`m-1 border border-tr-medium-gray rounded-full p-2 cursor-pointer ${isFirstPage ? 'cursor-not-allowed opacity-50 pointer-events-none' : ''}`}
                key="prev"
                onClick={() => onPageChange(currentPage - 1)}
            >
                <Icon name="arrowLeft" className="w-[16px] h-[16px] text-tr-gray-600"/>
            </li>
            {pages.map((page) => (
                <li
                    className={`m-1 text-tr-gray-600 cursor-pointer flex items-center justify-center h-8 w-8 ${page === currentPage ? 'rounded-full border border-tr-blue text-white bg-tr-blue' : ''}`}
                    key={page}
                    onClick={() => page === "... " ? onPageChange(currentPage - 1) : page === " ..." ? onPageChange(currentPage + 1) : onPageChange(page)}
                >
                    {page}
                </li>
            ))}
            <li
                className={`m-1 border border-tr-medium-gray rounded-full p-2 cursor-pointer ${isLastPage ? 'cursor-not-allowed opacity-50 pointer-events-none' : ''}`}
                key="next"
                onClick={() => onPageChange(currentPage + 1)}
            >
                <Icon name="arrowRight" className="w-[16px] h-[16px] text-tr-gray-600"/>
            </li>
        </ul>
    )
}

export default Pagination;