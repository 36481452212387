import React, { createContext, useState, useContext } from 'react';
import userService from '../services/users';
import ContextResponseModal from '../components/modals/ContextResponseModal';
import { useTranslation } from 'react-i18next';

const UserContext = createContext();

export const UsersProvider = ({ children }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({
    isOpen: false,
    error: false,
    message: '',
  });

  const toggleModal = (isModalOpen) => {
    setStatus({
      isOpen: !isModalOpen,
      error: false,
      message: '',
    });
  };

  const fetchUsers = async (data) => {
    setIsLoading(true);
    try {
      const response = await userService.getAllUsers(data);
      setUsers({
        data: response.data.data,
        pagination: { ...response.data },
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const exportUsers = async (data) => {
    setIsLoading(true);
    try {
      const response = await userService.exportUsers(data);
      // Create a Blob from your string data
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      // Create a download URL from the Blob
      const downloadUrl = window.URL.createObjectURL(blob);
      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'users-export.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const addUsers = async (usersData) => {
    setIsLoading(true);
    try {
      await userService.addUsersFromFile(usersData);
      setStatus({
        isOpen: true,
        error: false,
        message: t('import_successful'),
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const editUser = async (id, userInfo) => {
    setIsLoading(true);
    try {
      await userService.editUserById(id, userInfo);
      setStatus({
        isOpen: true,
        error: false,
        message: t('edit_successful'),
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const deleteUser = async (userId) => {
    setIsLoading(true);
    try {
      await userService.deleteUserById(userId);
      setStatus({
        isOpen: true,
        error: false,
        message: t('deleted_successfully'),
      });
      setIsLoading(false);
    } catch (error) {
      setStatus({
        isOpen: true,
        error: true,
        message: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <UserContext.Provider value={{ users, fetchUsers, isLoading, addUsers, editUser, deleteUser, exportUsers }}>
        {children}
      </UserContext.Provider>
      <ContextResponseModal status={status} isOpen={status.isOpen} onClose={toggleModal} />
    </>
  );
};

export const useUsers = () => useContext(UserContext);

export default UsersProvider;
