import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div>
      {i18n.language === "de" ? (
        <button onClick={() => changeLanguage("en")}>DE</button>
      ) : (
        <button onClick={() => changeLanguage("de")}>EN</button>
      )}
    </div>
  );
};

export default LanguageSwitcher;
