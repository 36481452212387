
import axiosWithAuth from './axiosWithAuth';

const getQueryString = (queries) => {
    return Object.keys(queries).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`]
    }, []).join('&');
};

const getTimetracking = async (filterData) => {
    let initialUrl = '/timetracking';
    if (filterData) {
        initialUrl = initialUrl + `?${getQueryString(filterData)}`;
    }
    return await axiosWithAuth.get(initialUrl);
};

const changeConfirmationStatus = async (id) => {
    return await axiosWithAuth.patch(`/timetracking/confirmation/${id}`)
}

export default {
    getTimetracking,
    changeConfirmationStatus
};