import axiosWithAuth from './axiosWithAuth';

const getQueryString = (queries) => {
    return Object.keys(queries).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`]
    }, []).join('&');
};

const getClasses = async () => {
    return await axiosWithAuth.get('/users/classes');
};

const getClassView = async (classList, filters) => {
    let initialUrl = `users/classView?classGroups=${classList}`;
    if (filters) {
        initialUrl = initialUrl + `&${getQueryString(filters)}`;
    }
    return await axiosWithAuth.get(initialUrl);
}

const setByodStatus = async (data) => {
    return await axiosWithAuth.put('users/byod', data);
}

const setSchoolHours = async (id, hoursData) => {
    return await axiosWithAuth.patch(`/class/school-hours/${id}`, hoursData);
}

export default {
    getClasses,
    getClassView,
    setByodStatus,
    setSchoolHours
}