import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedIfLoggedRoute from "./components/ProtectedIfLoggedRoute";
import { UsersProvider } from "./context/UsersContext";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import ClassView from "./pages/ClassView";
import Attendance from "./pages/Attendance";
import Import from "./pages/Import";
import UserAdministration from "./pages/UserAdministration";
import { PERMS } from "./utils/constants";
import PermissionsRouteCheck from "./components/PermissionsRouteCheck";
import ClassesProvider from "./context/ClassesContext";
import ChangePassword from "./pages/ChangePassword";
import AttendanceProvider from "./context/AttendanceContext";
import ClassTimes from "./pages/ClassTimes";
import Organization from "./pages/Organization";
import OrganizationProvider from './context/OrganizationContext';

const routes = [
  {
    path: "/",
    element: ClassView,
    protected: true,
    allowedPermissions: [
      PERMS.ATTENDANCE_ALL,
      PERMS.ATTENDANCE_OWN
    ]
  },
  {
    path: "/login",
    element: Login,
    protected: false,
    allowedPermissions: []
  },
  {
    path: "/attendance",
    element: Attendance,
    protected: true,
    allowedPermissions: [
      PERMS.ATTENDANCE_ALL,
      PERMS.ATTENDANCE_OWN
    ]
  },
  {
    path: "/users",
    element: UserAdministration,
    protected: true,
    allowedPermissions: [
      PERMS.MANAGE_USERS,
      PERMS.RESET_PASSWORD
    ]
  },
  {
    path: "/import",
    element: Import,
    protected: true,
    allowedPermissions: [
      PERMS.MANAGE_USERS
    ]
  },
  {
    path: "/change-password",
    element: ChangePassword,
    protected: false,
    allowedPermissions: []
  },
  {
    path: "/reset-password",
    element: ForgotPassword,
    protected: false,
    allowedPermissions: []
  },
  {
    path: "/class-times",
    element: ClassTimes,
    protected: true,
    allowedPermissions: [
      PERMS.ATTENDANCE_ALL,
      PERMS.ATTENDANCE_OWN
    ]
  },
  {
    path: "/organization",
    element: Organization,
    protected: true,
    allowedPermissions: [
      PERMS.MANAGE_ORGANIZATIONS
    ]
  }
];

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route) =>
        route.protected ? (
          <Route
            key={route.path}
            path={route.path}
            element={
              <UsersProvider>
                <ProtectedRoute>
                  <PermissionsRouteCheck requiredPermissions={route.allowedPermissions}>
                  <ClassesProvider>
                  <AttendanceProvider>
                  <OrganizationProvider>
                  {React.createElement(route.element)}
                  </OrganizationProvider>
                  </AttendanceProvider>
                  </ClassesProvider>
                  </PermissionsRouteCheck>
                </ProtectedRoute>
              </UsersProvider>
            }
          />
        ) : (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedIfLoggedRoute>
                {React.createElement(route.element)}
              </ProtectedIfLoggedRoute>
            }
          />
        )
      )}
    </Routes>
  );
};

export default AppRoutes;
