import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import TimeFrame from "../components/common/TimeFrame";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import ChangeClassModal from "../components/modals/ChangeClassModal";
import { useClasses } from "../context/ClassesContext";
import { useTranslation } from "react-i18next";
import { DEFAULT_SCHOOL_HOURS } from "../utils/constants";

const ClassTimes = () => {
  const { t } = useTranslation();
  const {
    fetchClassesForSchoolHours,
    selectedClasses,
    schoolHours,
    changeSchoolHours,
  } = useClasses();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [times, setTimes] = useState(DEFAULT_SCHOOL_HOURS);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFilterChange = (e, limiter) => {
    const { name, value } = e.target;
    const currentTime = times[name];
    currentTime[limiter] = value;
    setTimes((prevTimes) => ({
      ...prevTimes,
      [name]: currentTime,
    }));
  };

  const handleSaveTimes = async () => {
    const currentClass = schoolHours.filter(
      (item) => item.name === selectedClasses
    );
    await changeSchoolHours(currentClass[0].id, times);
  };

  useEffect(() => {
    fetchClassesForSchoolHours();
  }, []);

  useEffect(() => {
    if (selectedClasses && schoolHours && !selectedClasses.includes(",")) {
      const currentSchoolHours = schoolHours.filter(
        (item) => item.name === selectedClasses
      );
      setTimes((prev) => ({
        ...prev,
        ...currentSchoolHours[0].schoolHours,
      }));
    }
  }, [selectedClasses]);

  return (
    <div className="flex flex-col justify-between">
      <Header title={t("class_times")}>
        <div className="flex space-x-3 items-center text-xs pb-6 sm:pb-0">
          <span>
            {t("school_hours_for_class")}:{" "}
            <span className="font-bold">{selectedClasses}</span>
          </span>
          <Button className="bg-tr-blue flex" onClick={handleModalToggle}>
            <Icon name="arrowsUpDown" className="mr-2 rotate-90" />
            {t("change_class")}
          </Button>
        </div>
        <ChangeClassModal
          onDone={handleModalToggle}
          isModalOpen={isModalOpen}
          isMultySelect={false}
        />
      </Header>
      <div className="flex flex-col gap-1 mt-2 px-10 sm:px-0">
        <p>{t("monday")}</p>
        <TimeFrame
          dayOfWeek={"monday"}
          timeValue={times.monday}
          handleFilterChange={handleFilterChange}
        />
        <p>{t("tuesday")}</p>
        <TimeFrame
          dayOfWeek={"tuesday"}
          timeValue={times.tuesday}
          handleFilterChange={handleFilterChange}
        />
        <p>{t("wednesday")}</p>
        <TimeFrame
          dayOfWeek={"wednesday"}
          timeValue={times.wednesday}
          handleFilterChange={handleFilterChange}
        />
        <p>{t("thursday")}</p>
        <TimeFrame
          dayOfWeek={"thursday"}
          timeValue={times.thursday}
          handleFilterChange={handleFilterChange}
        />
        <p>{t("friday")}</p>
        <TimeFrame
          dayOfWeek={"friday"}
          timeValue={times.friday}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <div className="mt-4">
        <Button
          className="bg-tr-blue flex justify-center min-w-[100px]"
          onClick={handleSaveTimes}
        >
          <p>{t("save")}</p>
        </Button>
      </div>
    </div>
  );
};

export default ClassTimes;
