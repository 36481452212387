import React, { useState, useEffect } from "react";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import Header from "../components/common/Header";
import ChangeClassModal from "../components/modals/ChangeClassModal";
import ClassViewTable from "../components/tables/ClassViewTable";
import { useClasses } from "../context/ClassesContext";
import moment from "moment";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useTranslation } from "react-i18next";
import "moment/locale/de";

const ClassView = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    fetchStudents,
    students,
    fetchClassesList,
    selectedClasses,
    changeByodStatus,
    isLoading,
    schoolHours
  } = useClasses();
  const [filtering, setFiltering] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  const onSortBy = (sortKey) => {
    let checkSortKey = false;
    if (filtering?.sortBy === sortKey) {
      checkSortKey = true;
    }
    setFiltering((prev) => ({
      ...prev,
      sortBy: `${sortKey}`,
      sortDirection: `${
        checkSortKey ? (prev.sortDirection === "DESC" ? "ASC" : "DESC") : "DESC"
      }`,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setByodSelection = async (selectedStatus) => {
    await changeByodStatus(students, selectedStatus);
    await fetchStudents(filtering);
  };

  const setSingleByod = async (userId) => {
    await changeByodStatus(userId);
    await fetchStudents(filtering);
  };

  useEffect(() => {
    if (initialLoad) {
      fetchClassesList();
      setInitialLoad(false);
    } else if (!initialLoad && selectedClasses) {
      fetchStudents(filtering);
    }
  }, [selectedClasses, filtering]);

  const columns = [
    {
      key: "loggedIn",
      value: t("logged_in"),
      sortable: true,
    },
    {
      key: "isByodStatus",
      value: t("byod_status"),
      sortable: false,
    },
    {
      key: "classGroups",
      value: t("class"),
      sortable: true,
    },
    {
      key: "firstName",
      value: t("name"),
      sortable: true,
    },
    {
      key: "lastName",
      value: t("last_name"),
      sortable: true,
    },
    {
      key: "arrivalTime",
      value: t("arrival_time"),
      sortable: false,
    },
  ];

  return (
    // <ClassesProvider>
    <div>
      <Header title={t("class_view")}>
        <div className="flex space-x-3 items-center text-xs pb-3 sm:pb-0">
          <span>
            {t("class")}
            <span className="font-bold"> {selectedClasses} </span>
            {t("overview")}
          </span>
          <Button className="bg-tr-blue flex" onClick={handleModalToggle}>
            <Icon name="arrowsUpDown" className="mr-2 rotate-90" />
            {t("change_class")}
          </Button>
        </div>
        <ChangeClassModal
          onDone={handleModalToggle}
          isModalOpen={isModalOpen}
          isMultySelect={true}
        />
      </Header>
      <div className="flex justify-between text-sm p-7 pt-6 sm:pt-14">
        <span className="flex sm:space-x-5 space-y-3 sm:space-y-0 flex-col sm:flex-row">
          <Button
            className="bg-tr-green flex"
            onClick={() => setByodSelection(true)}
          >
            <Icon name="arrowUpCircle" className="mr-2" />
            {t("byod_start_all")}
          </Button>
          <Button
            className="bg-tr-red flex"
            onClick={() => setByodSelection(false)}
          >
            <Icon name="arrowDownCircle" className="mr-2" />
            {t("byod_end_all")}
          </Button>
        </span>
        <span className="pl-4">{moment(new Date()).locale(localStorage.getItem("i18nextLng")).format("D. MMMM YYYY")}</span>
      </div>
      <div>
        {isLoading ? (
          <div className="mt-4">
            <LoadingSpinner isLoading={isLoading} />
          </div>
        ) : (
          <div className="mb-5">
            <ClassViewTable
              columns={columns}
              initialEntries={students}
              onSortBy={onSortBy}
              setSingleByod={setSingleByod}
              schoolHours={schoolHours}
            />
          </div>
        )}
      </div>
    </div>
    // </ClassesProvider>
  );
};

export default ClassView;
