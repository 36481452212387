import React, { useEffect, useState } from "react";
import Modal from "../common/Modal";
import Icon from "../common/Icon";
import Button from "../common/Button";
import { useUsers } from "../../context/UsersContext";
import Select from "react-select";
import { useClasses } from "../../context/ClassesContext";
import { useTranslation } from "react-i18next";
import { PERMS, ROLE_NAMES } from "../../utils/constants";
import { UserAuth } from "../../context/AuthContext";

const EditUserModal = ({
  isOpen,
  onClose,
  passedUser,
  selectedUserId,
  selectedUserRole,
  onUserChange,
}) => {
  const { t } = useTranslation();
  const { editUser } = useUsers();
  const { classes, fetchClassesForEdit } = useClasses();
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    classGroupIds: "",
    newPassword: "",
    confirmPassword: "",
    isRemote: false,
    role: ""
  });
  const [error, setError] = useState({
    passwordError: false,
    infoError: false
  });
  const { user } = UserAuth();

  const saveChanges = async () => {
    if (!userInfo.firstName.length || !userInfo.lastName.length) {
      setError((prev) => ({
        ...prev,
        infoError: true,
        passwordError: false
      }));
      return;
    } else {
      setError((prev) => ({
        ...prev,
        infoError: false
      }));
    }
    if (userInfo.newPassword.length || userInfo.confirmPassword.length) {
      if (userInfo.newPassword !== userInfo.confirmPassword) {
        setError((prev) => ({
          ...prev,
          passwordError: true,
        }));
        return;
      } else {
        setError((prev) => ({
          ...prev,
          passwordError: false,
        }));
      }
    }
    await editUser(userId, userInfo);
    onUserChange();
    onClose(isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserInfo((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (e) => {
    if (userRole === "STUDENT") {
      setUserInfo((prevFilters) => ({
        ...prevFilters,
        classGroupIds: [e.value],
      }));
    } else {
      const classArray = [];
      e.map((item) => classArray.push(item.value));
      setUserInfo((prevFilters) => ({
        ...prevFilters,
        classGroupIds: classArray,
      }));
    }
  };

  const handleSelectRoleChange = (e) => {
    setUserInfo((prevFilters) => ({
        ...prevFilters,
        role: e.value,
    }));
  };

  useEffect(() => {
    fetchClassesForEdit();
    setUserId(selectedUserId);
    setUserRole(selectedUserRole);
    setUserInfo(() => ({
      newPassword: "",
      confirmPassword: "",
      ...passedUser,
    }));
    setError({
      passwordError: false,
    });
  }, [passedUser]);

  return (
    <Modal isOpen={isOpen} title={t("edit_user")} onClose={onClose}>
      <div className="flex flex-col gap-4">
        {user.role.permissions.includes(PERMS.MANAGE_USERS) && (
        <>
            <div className="flex gap-4">
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <span className="px-3">
                        <Icon name="user" className="h-4 w-4 text-tr-gray" />
                    </span>
                    <input
                        id="firstName"
                        placeholder={t("first_name")}
                        name="firstName"
                        type="text"
                        value={userInfo.firstName}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <span className="px-3">
                        <Icon name="user" className="h-4 w-4 text-tr-gray" />
                    </span>
                    <input
                        id="lastName"
                        placeholder={t("last_name")}
                        name="lastName"
                        type="text"
                        value={userInfo.lastName}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <span className="px-3">
                    <Icon name="mail" className="h-4 w-4 text-tr-gray" />
                </span>
                <input
                    id="email"
                    placeholder={t("email")}
                    name="email"
                    type="email"
                    value={userInfo.email}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                />
            </div>
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <span className="px-3">
                    <Icon name="userGroup" className="h-6 w-6 text-tr-gray" />
                </span>
                <Select
                    defaultValue={
                    passedUser.classGroupIds &&
                    passedUser.classGroupIds.map((item) => ({ value: item, label: item }))
                    }
                    options={
                    classes && classes.map((item) => ({ value: item, label: item }))
                    }
                    className="w-full py-[2px] text-sm sm:text-lg"
                    placeholder={<div className="text-tr-gray-400 text-sm sm:text-lg">{t("select_class")}</div>}
                    onChange={handleSelectChange}
                    isMulti={userRole !== "STUDENT"}
                    styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        border: 0,
                        boxShadow: "none",
                        background: "transparent",
                    }),
                    }}
                />
            </div>
            <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                <span className="px-3">
                    <Icon name="role" className="h-4 w-4 text-tr-gray" />
                </span>
                <Select
                    defaultValue={
                        passedUser.role &&
                        ({ value: passedUser.role, label: passedUser.role })
                    }
                    options={
                        Object.values(ROLE_NAMES).map((item) => ({ value: item, label: item }))
                    }
                    className="w-full py-[2px] text-sm sm:text-lg"
                    placeholder={<div className="text-tr-gray-400 text-sm sm:text-lg">{t("select_role")}</div>}
                    onChange={handleSelectRoleChange}
                    isMulti={false}
                    styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        border: 0,
                        boxShadow: "none",
                        background: "transparent",
                    }),
                    }}
                />
            </div>     
        </>
        )}
        {user.role.permissions.includes(PERMS.RESET_PASSWORD) && (
            <div className="flex gap-4">
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <span className="px-3">
                        <Icon name="lock" className="h-4 w-4 text-tr-gray" />
                    </span>
                    <input
                        id="newPassword"
                        placeholder={t("new_password_placeholder")}
                        name="newPassword"
                        type="password"
                        value={userInfo.newPassword}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
                <div className="flex items-center border border-tr-medium-gray rounded-[50px] bg-white">
                    <span className="px-3">
                        <Icon name="lock" className="h-4 w-4 text-tr-gray" />
                    </span>
                    <input
                        id="confirmPassword"
                        placeholder={t("confirm_password_placeholder")}
                        name="confirmPassword"
                        type="password"
                        value={userInfo.confirmPassword}
                        onChange={handleInputChange}
                        required
                        className="flex-1 text-sm appearance-none rounded-[50px] border-0 px-3 py-2 text-primary focus:z-10 focus:outline-none focus:ring-indigo-500 sm:text-lg"
                    />
                </div>
            </div>   
        )}
        {error.passwordError && (
          <div className="w-full flex justify-center">
            <span className="text-error">{t("password_dont_match")}</span>
          </div>
        )}
        {user.role.permissions.includes(PERMS.MANAGE_USERS) && (
            <div>
                <div className="flex items-center gap-2 ml-1">
                    <input
                        id="isRemote"
                        type="checkbox"
                        name="isRemote"
                        defaultChecked={passedUser.isRemote}
                        onChange={handleInputChange}
                    />
                    <span className="text-primary">{t("login_externally")}</span>
                </div>
            </div>
        )}
        {error.infoError && (
          <div className="w-full flex justify-center">
            <span className="text-error">{t("name_required")}</span>
          </div>
        )}
        <div className="flex gap-4">
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={saveChanges}
          >
            {t("update_user")}
          </Button>
          <Button
            className="justify-center bg-tr-blue relative flex mx-auto w-full mt-4"
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserModal;
