import axiosWithAuth from './axiosWithAuth';

const getQueryString = (queries) => {
  return Object.keys(queries).reduce((result, key) => {
      return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`]
  }, []).join('&');
};

const getAllOrganizations = async (filterData) => {
  let initialUrl = '/tenants';
  if (filterData) {
    initialUrl = initialUrl + `?${getQueryString(filterData)}`;
  }
  return await axiosWithAuth.get(initialUrl);
};

const editOrganizationById = async (id, data) => {
  return await axiosWithAuth.put(`/tenants/${id}`, data);
}

const deleteOrganizationById = async (id) => {
  return await axiosWithAuth.delete(`/tenants/${id}`);
}

const addOrganization = async (data) => {
  return await axiosWithAuth.post(`/tenants`, data);
};

export default {
  getAllOrganizations,
  editOrganizationById,
  deleteOrganizationById,
  addOrganization
};