import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "./common/Icon";
import { UserAuth } from "../context/AuthContext";
import { PERMS } from "../utils/constants";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const SIDEBAR_ROUTES = {
  "/": "classView",
  "/attendance": "attendance",
  "/import": "import",
  "/users": "users",
  "/class-times": "classTimes",
  "/organization": "organization"
};

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("classView");
  const location = useLocation();
  const { user, handleLogout } = UserAuth();

  const tabClassName = (tabName) => {
    return `flex items-center space-x-2 m-4 px-4 py-3 ${
      activeTab === tabName
        ? "border rounded-3xl border-tr-medium-gray bg-tr-light-gray text-tr-black"
        : "text-tr-gray"
    }`;
  };

  const iconClassName = (tabName) => {
    return `text-tr-blue ${
      activeTab === tabName ? "text-tr-blue" : "text-tr-gray"
    }`;
  };

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const activeTabName = SIDEBAR_ROUTES[`/${currentPath}`] || "classView";
    setActiveTab(activeTabName);
  }, [location]);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 flex flex-col w-64 bg-white inset-y-0 left-0 transform transition duration-200 ease-in-out z-10 absolute lg:relative`} // Reduced z-index
      >
        <img
          className="px-6 my-6 pb-2 h-11 object-contain border-b border-tr-medium-gray"
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="logo"
        />
        <nav className="text-sm text-tr-gray">
          {(user.role.permissions.includes(PERMS.ATTENDANCE_ALL) ||
            user.role.permissions.includes(PERMS.ATTENDANCE_OWN)) && (
            <NavLink
              to="/"
              className={tabClassName("classView")}
              onClick={() => setActiveTab("classView")}
            >
              <span>
                <Icon name="userGroup" className={iconClassName("classView")} />
              </span>
              <span>{t("class_view")}</span>
            </NavLink>
          )}
          {(user.role.permissions.includes(PERMS.ATTENDANCE_ALL) ||
            user.role.permissions.includes(PERMS.ATTENDANCE_OWN)) && (
            <NavLink
              to="/attendance"
              className={tabClassName("attendance")}
              onClick={() => setActiveTab("attendance")}
            >
              <span>
                <Icon
                  name="clipboardDocumentList"
                  className={iconClassName("attendance")}
                />
              </span>
              <span>{t("attendance")}</span>
            </NavLink>
          )}
          {user.role.permissions.includes(PERMS.MANAGE_USERS) && (
            <NavLink
              to="/import"
              className={tabClassName("import")}
              onClick={() => setActiveTab("import")}
            >
              <span>
                <Icon
                  name="arrowUpOnSquare"
                  className={iconClassName("import")}
                />
              </span>
              <span>{t("import_students")}</span>
            </NavLink>
          )}
          {(user.role.permissions.includes(PERMS.MANAGE_USERS) ||
            user.role.permissions.includes(PERMS.RESET_PASSWORD)) && (
            <NavLink
              to="/users"
              className={tabClassName("users")}
              onClick={() => setActiveTab("users")}
            >
              <span>
                <Icon name="listBullet" className={iconClassName("users")} />
              </span>
              <span>{t("user_administration")}</span>
            </NavLink>
          )}
          {(user.role.permissions.includes(PERMS.ATTENDANCE_ALL) ||
            user.role.permissions.includes(PERMS.ATTENDANCE_OWN)) && (
            <NavLink
              to="/class-times"
              className={tabClassName("classTimes")}
              onClick={() => setActiveTab("classTimes")}
            >
              <span>
                <Icon name="clock" className={iconClassName("classTimes")} />
              </span>
              <span>{t("class_times")}</span>
            </NavLink>
          )}
          {user.role.permissions.includes(PERMS.MANAGE_ORGANIZATIONS) && (
            <NavLink
              to="/organization"
              className={tabClassName("organization")}
              onClick={() => setActiveTab("organization")}
            >
              <span>
                <Icon
                  name="organization"
                  className={`${iconClassName("organization")} !w-4 !h-4`}
                />
              </span>
              <span>{t("organization")}</span>
            </NavLink>
          )}
        </nav>
        <div className="mt-auto">
          <img
            src={process.env.PUBLIC_URL + "/child.png"}
            alt="child"
            className="object-contain w-full h-auto"
          />
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="flex justify-between items-center px-10 py-[14.2px] border-b border-l border-tr-medium-gray bg-white z-20">
          {" "}
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="flex items-center lg:hidden pt-2"
          >
            <Icon name="bars3" className="text-tr-black" />
            <img
              className="object-contain ml-4 w-40 hidden sm:block"
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="logo"
            />
          </button>
          <div
            className="hidden lg:flex lg:items-center lg:w-auto w-full"
            id="menu"
          ></div>
          <div className="flex items-center space-x-5">
            <span>
              {user.firstName} {user.lastName}
              <p className="text-[10px]">{user.email}</p>
            </span>
            <button
              title="Log out"
              className="border border-tr-medium-gray p-1 rounded cursor-pointer hover:opacity-60"
              onClick={() => handleLogout()}
            >
              <Icon
                name="arrowRightOnRectangle"
                className="text-tr-black"
              />
            </button>
            <div className="flex justify-end m-2">
              <LanguageSwitcher />
            </div>
          </div>
        </header>

        <main className="px-8 flex-1 overflow-x-hidden overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
