import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import AppRoutes from "./AppRoutes";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    detection: {
      // options for language detection - customize as needed
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <Router>
      <div className="App">
        <section>
          <div>
            <AuthContextProvider>
              <AppRoutes />
            </AuthContextProvider>
          </div>
        </section>
      </div>
    </Router>
  );
}

export default App;
